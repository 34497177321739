@media screen and (max-width: 768px) {
  .card-info-container .btn {
      width: 100%;
  }
}

.card-link-element {
  display: inline-block;
  width: 100%;
  transition: all .2s ease-in-out;
  box-sizing: border-box;
}

.gift-card-image {
  border-radius: 20px;
  width: 90%;
  aspect-ratio: 16/9;
  box-sizing: border-box;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.card-link-element:hover {
  transform: scale(1.1);
}

.card-price-tag {
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: $primary-color;
  color: $white-color;
  font-weight: 600;
  font-size: $font-size-mini;
  opacity: 0.8;
  padding: 0 15px;
  box-sizing: border-box;
  transition: all .2s ease-in-out;
  display: inline-block;
  position: absolute;
  top: 0;
}

.card-flag-fi {
  border-radius: 6px;
  box-sizing: content-box;
  aspect-ratio: 1 / 1;
}

.card-info-container {
  padding-top: 10vh;
  min-height: 100vh;
}

.denomination-badge {
  padding: 5px 10px;
}

.quantity-btn {
  font-size: 32px;
  width: 45px !important;
  height: 45px;
  margin-top: 0;
  margin-bottom: 0 !important;
  padding: 0;
}

.quantity-input {
  width: 45px;
  height: 45px !important;
  display: inline;
  margin: 0 5px;
  text-align: center;
  font-size: 1.2em;
  border: none;
}

.price-container-input, .denomination-container-input {
  input {
    font-size: 1.2em;
    padding: 5px 10px !important;
    height: 42px !important;
  }

  .input-group-text {
    background-color: $primary-color;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid $primary-color;
    cursor: pointer;
    width: 80px;
    padding-right: 0 !important;    
    height: 42px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    
    p {
      color: $white-color;
      font-weight: 600;
      margin: auto;
    }
  }
}
.price-container-input {
  .input-group-text:hover {
    box-shadow: $box-shadow-raised;
    background-color: $primary-states-color;
  }
}

.modal-body {
  max-height: 80vh;
  overflow-y: scroll;
}

.modal-currency-container {
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  line-height: 1em;
  font-size: 1em;
  padding: 10px;
  border-radius: $border-radius-extreme;
}

.modal-currency-container:hover {
  background-color: $light-gray;
}

.modal-currency-symbol {
  border: 2px solid $primary-color;
  color: $primary-color;
  padding: 22.5px 0;
  width: 65px;
  height: 65px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 20px;
}

.timer-badge {
  width: 60px;
  margin: auto;
}

.email-input {
  border-radius: $btn-round-radius !important;
}

.gcnhub-range-input + .input-group-append .input-group-text {
  border-top-right-radius: $btn-round-radius !important;
  border-bottom-right-radius: $btn-round-radius !important;
}