@media (min-width: 1200px) {
  .container {
      max-width: 1440px;
  }
}

.main {
  padding-top: 10vh;
  min-height: 90vh;
}

#sliderRegular {
  height: 20px;
  border-radius: 10px;
  background-color: $white-color;

  .noUi-base::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: $default-color-opacity;
    clip-path: ellipse(1000% 20px at 1000% 10px);
    position: absolute;
    border-radius: 10px;
  }

  .noUi-connects {
    clip-path: ellipse(1000% 20px at 1000% 10px);
    
    .noUi-connect {
      background-color: $primary-color; 
    }
  }

  .noUi-origin {
    height: 100%;

    .noUi-handle {
      margin-top: 0;
      height: 100%;
      width: auto;
      aspect-ratio: 1 / 1;
      border: 1px solid $primary-color;
    }

    .noUi-handle::before {
      content: '';
      width: 50%;
      height: 50%;
      display: block;
      background-color: $primary-color;
      border-radius: 50%;
      position: absolute;
      top: 25%;
      left: 25%;
    }
  }
}

.btn-primary {
  height: 60px;
  font-size: 1em;
}

input.form-control {
  height: 60px;
  font-size: 1em;
}

#brand-search-input {
  border-right: none;
  + div .input-group-text {
    border-left: none;
  }
}

#country-search-input:disabled {
  background-color: $white-bg;
  cursor: pointer;
}

.cookies-container {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  left: 0;
  right: 0;
  max-width: 100%;
  background-color: $white-color;
  box-shadow: $box-shadow-raised;

  .row {
    align-items: center;

    :nth-child(1) {
      color: $light-black;
    }
    :nth-child(2) {
      display: flex;
      justify-content: flex-end;

      button {
        color: $white-color;
        font-weight: 700;
      }
    }
  }
}

.contact-us-container {
  textarea {
    border: 1px solid #E3E3E3 !important;
    max-height: 200px !important;
    font-size: $font-size-base;

    &:focus {
      border: 1px solid $primary-color !important;
    }
  }
}